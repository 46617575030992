<!-- <template>
  <v-card
    elevation="0"
    outlined
    class="d-flex flex-column px-4"
    height="30rem"
  >
    <div class="pt-4">
      <v-img :src="image" :alt="name" height="200" contain></v-img>
    </div>

    <v-card-title class="pb-0">
      <div class="text-h6">{{ name }}</div>
    </v-card-title>

    <v-card-text class="flex-column flex-grow-1 pb-0">
      <v-row align="center" class="mx-0 mb-2 no-gutters">
        <v-rating
          :value="average_rating"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
        <div class="grey--text ms-2">
          {{ `${average_rating} (${user_ratings_total})` }}
        </div>
      </v-row>

      <div class="text-h6 mb-2">
        {{ `¥${price.toFixed(2)}` }}
      </div>

      <div class="text-body-2 text-justify">
        {{ truncateText(description, 42) }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        style="background-color: #1e88e5; opacity: 0.9"
        color="white"
        text
        @click="ProductDetails"
      >
        Place Order
      </v-btn>
    </v-card-actions>
  </v-card>
</template> -->

<template>
  <div>
    <v-card elevation="0" outlined class="d-flex flex-column flex-sm-row">
      <div
        class="d-flex justify-center"
        :style="{
          width: $vuetify.breakpoint.xs ? 'auto' : '12.625rem',
          height: $vuetify.breakpoint.xs ? '10.5rem' : '12.5rem',
          padding: '1rem 0px',
        }"
      >
        <v-img class="p-4" :src="image" :alt="name" contain></v-img>
      </div>

      <div class="d-flex flex-column flex-grow-1 pa-4">
        <v-card-title class="pb-1 pt-0 pe-0">
          <v-row no-gutters>
            <v-col cols="9" class="d-flex align-center">
              <div
                class="font-weight-bold w-100"
                :class="[
                  $vuetify.breakpoint.xs ? 'text-subtitle-2' : 'text-h6',
                ]"
              >
                {{ name }}
              </div>
            </v-col>

            <v-col cols="3">
              <v-container>
                <v-row class="d-flex justify-end">
                  <v-btn
                    class="px-4"
                    color="primary"
                    @click="openProductDetailsDialog"
                    :small="$vuetify?.breakpoint?.smAndDown"
                    :x-small="$vuetify?.breakpoint?.xsOnly"
                  >
                    Details
                  </v-btn>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="py-1 flex-grow-1 d-flex flex-column">
          <div
            class="mb-1"
            :class="[
              $vuetify.breakpoint.xs ? 'text-body-2' : 'text-subtitle-1',
            ]"
          >
            {{ `¥${formatPrice(price)}` }}
          </div>

          <div
            :class="[$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-2']"
          >
            <div class="mb-1">
              <span class="font-weight-bold"></span>
              {{
                `${tasteType} ${
                  ingredientType.length > 0 ? `(${ingredientType})` : ""
                }`.trim()
              }}
            </div>

            <div class="mb-1">
              <span>オススメの飲み方:</span>
              {{ `${recommendedWayToDrink}` }}
            </div>

            <div class="mb-1">
              <span>容量:</span>
              {{ `${volume}` }}
            </div>

            <div class="mb-1">
              <span>その他の容量項目:</span>
              {{
                `${
                  volumeVariant?.length === 0 || volumeVariant == null
                    ? "なし"
                    : volumeVariant
                }`
              }}
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="py-0 ps-4 pe-0">
          Rate this:
          <v-spacer></v-spacer>
          <div>
            <v-row>
              <v-dialog v-model="showRatingsDialog" max-width="600px">
                <v-form ref="ratingForm" v-model="isRatingFormValid">
                  <v-card>
                    <v-card-title> </v-card-title>
                    <v-card-text class="py-0">
                      <v-row class="d-flex align-center">
                        <v-col>
                          <p class="font-weight-bold text-subtitle-1 pe-4">
                            この飲料を飲んだことがありますか？
                          </p>
                          <v-container fluid>
                            <v-radio-group
                              class="my-0 py-0"
                              v-model="isTastedBefore"
                              column
                              :rules="[
                                (v) =>
                                  !!v ||
                                  'At least one option must be selected!',
                              ]"
                            >
                              <v-radio label="はい" value="yes"></v-radio>
                              <v-radio label="いいえ" value="no"></v-radio>
                            </v-radio-group>
                          </v-container>
                        </v-col>
                        <!-- <v-col cols="2">
                          <v-btn fab depressed>
                            <v-icon> mdi mdi-arrow-right </v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="pa-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        class="px-4"
                        color="primary"
                        @click="submitRatingsWithFeedback"
                        :small="$vuetify?.breakpoint?.smAndDown"
                        :x-small="$vuetify?.breakpoint?.xsOnly"
                      >
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-btn
                color="primary lighten-2"
                class="ma-2"
                dark
                icon
                @click="handleRatingThumbsUp"
              >
                <v-icon>{{
                  submittedRatingType === "up"
                    ? "mdi-thumb-up"
                    : "mdi-thumb-up-outline"
                }}</v-icon>
              </v-btn>
              <v-btn
                color="primary lighten-2"
                class="ma-2"
                dark
                icon
                @click="handleRatingThumbsDown"
              >
                <v-icon>{{
                  submittedRatingType === "down"
                    ? "mdi-thumb-down"
                    : "mdi-thumb-down-outline"
                }}</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-card-actions>
      </div>
    </v-card>
    <ProductDetails
      :isOpenDialog.sync="showItemDetailsDialog"
      :selectedProduct="productDetails"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductDetails from "./ProductDetails.vue";
import axios from "axios";

export default {
  name: "ProductCard",
  components: {
    ProductDetails,
  },
  computed: {
    ...mapGetters("credentials_form", ["credentialsFromData"]),
    productDetails() {
      return {
        id: this.id,
        name: this.name,
        image: this.image,
        price: this.formatPrice(this.price),
        description: this.description,
        categories: this.categories,
        tasteType: this.tasteType,
        ingredientType: this.ingredientType,
        volume: this.volume,
        volumeVariant: this.volumeVariant,
        childItems: this.childItems,
        recommendedWayToDrink: this.recommendedWayToDrink,
        recommendedJapaneseFood: this.recommendedJapaneseFood,
        recommendedWesternFood: this.recommendedWesternFood,
        recommendedChineseAndEthnicFood: this.recommendedChineseAndEthnicFood,
        recommendedSweetsAndFruit: this.recommendedSweetsAndFruit,
      };
    },
  },
  data() {
    return {
      showItemDetailsDialog: false,
      isRatingFormValid: false,
      isTastedBefore: null,
      feedbackTextForRating: "",
      showRatingsDialog: false,
      submittedRatingType: null,
      temporaryRatingType: null,
      // isSubmittingRating: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    averageRating: {
      type: Number,
      default: 0,
    },
    userRatingsTotal: {
      type: Number,
      default: 0,
    },
    tasteType: {
      type: Array,
      default: () => [],
    },
    ingredientType: {
      type: Array,
      default: () => [],
    },
    volume: {
      type: Array,
      default: () => [],
    },
    volumeVariant: {
      type: Array,
      default: () => [],
    },
    childItems: {
      type: Array,
      default: () => [],
    },
    recommendedWayToDrink: {
      type: Array,
      default: () => [],
    },
    recommendedJapaneseFood: {
      type: Array,
      default: () => [],
    },
    recommendedWesternFood: {
      type: Array,
      default: () => [],
    },
    recommendedChineseAndEthnicFood: {
      type: Array,
      default: () => [],
    },
    recommendedSweetsAndFruit: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    openProductDetailsDialog() {
      this.showItemDetailsDialog = true;
    },
    formatPrice(price) {
      if (price < 1000) {
        return price.toString();
      }
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    truncateText(text, length) {
      if (text.length <= length) {
        return text;
      }
      return text.slice(0, length) + "...";
    },
    handleRatingThumbsUp() {
      this.temporaryRatingType = "up";
      this.showRatingsDialog = true;
    },
    handleRatingThumbsDown() {
      this.temporaryRatingType = "down";
      this.showRatingsDialog = true;
    },
    submitRatingsWithFeedback() {
      if (!this.$refs.ratingForm.validate()) {
        return;
      }
      // this.isSubmittingRating = true;

      // Set feedback based on radio selection
      if (this.isTastedBefore === "yes") {
        this.feedbackTextForRating = "過去に飲んだことがある商品";
      } else {
        this.feedbackTextForRating = "まだ飲んだことがない商品";
      }

      const headers = {
        accept: "application/json",
        "project-key": this.credentialsFromData["projectKey"],
        "api-key": this.credentialsFromData["apiKey"],
      };

      const ratingRequestBody = {
        user_id: "a0cc6beb-2909-459b-be55-62196af78ce4",
        member_id: "df3456tg-2909-459b-be55-62196afedf85",
        item_id: this.id,
        rating: this.temporaryRatingType === "up" ? 1 : 0,
        feedback: this.feedbackTextForRating,
      };

      axios
        .post(
          `${process.env.VUE_APP_RECOMMENDER_API}/v1/items/rating`,
          ratingRequestBody,
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            // Only set the submitted rating after successful API response
            this.submittedRatingType = this.temporaryRatingType;
            this.showRatingsDialog = false;
            this.feedbackTextForRating = "";
          }
        })
        .catch((error) => {
          console.error("Rating submission failed:", error);
        })
        .finally(() => {
          // this.isSubmittingRating = false;
          this.temporaryRatingType = null;
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.text-truncate-three-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body-text-container {
  /* Body font sizes */
  --body-font-size-desktop: 1rem;
  /* 16px */
  --body-font-size-tablet: 0.875rem;
  /* 14px */
  --body-font-size-mobile: 0.8125rem;
  /* 13px */
}

.body-text {
  font-size: var(--body-font-size-desktop) !important;
  transition: all 0.3s ease;
}

@media (max-width: 600px) {
  .body-text {
    font-size: var(--body-font-size-mobile) !important;
  }
}
</style>
